import MaterialsExpenditureForm, { MaterialsExpenditureItem } from "../../features/MaterialsExpenditureForm/MaterialsExpenditureForm";
import { FormApi } from "final-form";

interface IProps {
  values: {
    items: Array<MaterialsExpenditureItem>;
  };
  index: number;
  onDelete?: (index: number) => void;
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
  isPending?: boolean;
}

const fields: Array<keyof IProps["values"]["items"][number]> = [
  "name",
  "measure",
  "count",
  "price",
  "producer",
  "number",
  "brand",
  "type",
  "from_estimate",
];

const MaterialsCreateExpenditureForm = ({ values, index, onDelete, form, isPending }: IProps) => {
  const getName = getNameWithIndex(index);

  const onRemove = () => {
    const newValues = form.getState().values.items;

    form.change(
      "items",
      newValues.filter((_: unknown, i: number) => i !== index)
    );
    onDelete?.(index);
  };

  const itemFieldNames = Object.fromEntries(fields.map((field) => [field, getName(field)])) as Record<
    (typeof fields)[number],
    string
  >;

  return (
    <MaterialsExpenditureForm
      itemFieldNames={itemFieldNames}
      canRemove={index > 0}
      onRemoveDirectly={onRemove}
      isPending={isPending}
      isFromEstimate={values.items[index]?.from_estimate}
    />
  );
};

export default MaterialsCreateExpenditureForm;

function getNameWithIndex(index: number) {
  return (name: string) => {
    return `items.[${index}].${name}`;
  };
}
